import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";


class StatuePage extends React.Component {

    constructor(props) {
        super(props);

            this.state = {
                textStore: {},
                isLoading: true
            }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        //  const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid><TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'statue'}
                    imageLinkId={"statue-title-image-id"}
                    headerId={"statue-title-header-id"}
                    subheaderId={"statue-subtitle-header-id"}
                    small={true}
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>SATZUNG</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <h2>
                            <EditSimpleValueComponent textStore={this.state.textStore} id={"statue-title-sector-1-id"}
                                                      namespace={'statue'}/>
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <p className={"text-block"}>

                            <EditHTMLComponent textStore={this.state.textStore} id={"statue-icon-card-1-subheader-id"}
                                               namespace={'statue'}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}>
                        <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    browserHistory.push("/download");
                                    this.setState({})
                                }}>
                            {"Satzung herunterladen".toUpperCase()}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default StatuePage